import get from 'lodash/get';
import find from 'lodash/find';
import logger from 'server-utils/logger';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import { parseAbTestValue } from 'client-utils/utilities-abtest';
import { ADOBE_QUERY_PARAMS } from 'server/utilities/adobeDebug';
import { openModal } from 'shared/components/Modal/actions';

export const SET_TITLE = 'SET_TITLE';
export const SET_META_INFO = 'SET_META_INFO';
export const SET_META_INFO_CTP = 'SET_META_INFO_CTP';
export const SET_META_INFO_PLP = 'SET_META_INFO_PLP';
export const SET_PAGE_ID = 'SET_PAGE_ID';
export const SET_CANONICAL_URL = 'SET_CANONICAL_URL';
export const SET_META_DESCRIPTION = 'SET_META_DESCRIPTION';
export const SET_META_TAG = 'SET_META_TAG';
export const SET_TITLE_OVERRIDE = 'SET_TITLE_OVERRIDE';
export const PAGE_ID_HOME = 'PAGE_ID_HOME';
export const PAGE_ID_PDP = 'PAGE_ID_PDP';
export const PAGE_ID_PLP = 'PAGE_ID_PLP';
export const PAGE_ID_SRP = 'PAGE_ID_SRP';
export const PAGE_ID_PLA = 'PAGE_ID_PLA';
export const PAGE_ID_CAT_TMPL = 'PAGE_ID_CAT_TMPL';
export const PAGE_ID_SPECIAL_OFFERS = 'PAGE_ID_SPECIAL_OFFERS';
export const PAGE_ID_CCPA_FORM = 'PAGE_ID_CCPA_FORM';
export const PAGE_ID_LOGIN = 'PAGE_ID_LOGIN';
export const PAGE_ID_RESETPW = 'PAGE_ID_RESETPW';
export const PAGE_ID_REGISTER = 'PAGE_ID_REGISTER';
export const PAGE_ID_EDITORIAL = 'PAGE_ID_EDITORIAL';
export const PAGE_ID_INTL_CART = 'PAGE_ID_INTL_CART';
export const PAGE_VISITED = 'PAGE_VISITED';
export const RESOLVED_BREADCRUMB_CONTENT = 'RESOLVED_BREADCRUMB_CONTENT';
export const RESOLVED_EDITORIAL_BREADCRUMB_CONTENT = 'RESOLVED_EDITORIAL_BREADCRUMB_CONTENT';

export const LOADING_ABTESTOPT = 'LOADING_ABTESTOPT';
export const RESOLVED_ABTESTOPT = 'RESOLVED_ABTESTOPT';
export const REJECTED_ABTESTOPT = 'REJECTED_ABTESTOPT';

export const LOADING_ABTEST = 'LOADING_ABTEST';
export const RESOLVED_ABTEST = 'RESOLVED_ABTEST';
export const REJECTED_ABTEST = 'REJECTED_ABTEST';
export const RESET_ABTEST = 'RESET_ABTEST';

export const LOADING_ABTESTS = 'LOADING_ABTESTS';
export const RESOLVED_ABTESTS = 'RESOLVED_ABTESTS';
export const REJECTED_ABTESTS = 'REJECTED_ABTESTS';

export const SET_TEST_FROM_COOKIE = 'SET_TEST_FROM_COOKIE';
export const SET_TEST_FROM_RESPONSE = 'SET_TEST_FROM_RESPONSE';

export const FETCH_PAGE_SIZE_MBOX_ID = 'MobileSRPFetchSize';
export const CP_PERSONALIZATION_MBOX_ID = 'cppersonalization';
export const NEW_ASPECT_RATIO_MBOX_ID = 'NEW_ASPECT_RATIO';

export const DOM_LOAD_COMPLETE = 'DOM_LOAD_COMPLETE';

export const PLP_PCS_RECOMMEDATION_MBOX_ID = 'ProductCategorySort';

export const PLP_PCS_RECOMMEDATION_TEST = 'pcsCategorySort';
export const VISUAL_NAVIGATION_TEST = 'visnav';

export const OPTIMIZELY_TEST_OPT_ID = 'plptest';
export const SIMPLIFIED_MINI_CART = 'simplifiedminicart';
export const PDP_LAYOUT = 'pdplayout';
export const PDP_FOUNDATION = 'pdpfoundation';
export const ATB_UPT = 'atbupt';
export const AFTER_PAY = 'afterpay';
export const CAROUSEL_REDESIGN = 'carred';
export const APP_SET_UEM_PARAM = 'APP_SET_UEM_PARAM';
export const COLOR_COMPONENT = 'color';
export const UIPRIC_TEST = 'UIPRIC';
export const PANEL_TEST = 'nmsw0002';
export const CONTENT_TEST = 'nmsw0004';
export const AFFIRM_TEST = 'nmpd0003';
export const PDP_REDESIGN_TEST = 'nmpd0004';
export const PDP_PLA_TEST = 'nmpla0003';
export const TYPEAHEAD_TEST = 'ns1';
export const BLUECORE_TEST = 'nmbc0001';
export const VISUALLY_SIMILAR_YMAL_TEST = 'nmsss0002';
export const IMAGES_SCROLLING_TEST = 'nmpd0005';
export const GROUPS_REDESIGN_TEST = 'nmgrppd0001';
export const RESOLVED_EDITORIAL_CLASS = 'RESOLVED_EDITORIAL_CLASS';
export const OPEN_SEARCH_BOX_MODAL = 'SearchBoxModal';
export const OPEN_COLLAPSE_SEARCH_BOX_MODAL = 'CollapseSearchModal';
export const ABTEST_SALE = 'nmsa0001';
export const AB_TEST_SITE_TICKER = 'nmfs0002';
export const ABTEST_BF_SALE = 'tl150';
export const ABTEST_EXPOSE_TOP_NAV = 'tl230';
export const ABTEST_ABANDON_CART_RECS = 'tl337';
export const ABTEST_COLLAPSED_SEARCH_BAR = 'tl229';

export function setTitle(title, separator = '') {
  return (dispatch) => dispatch({ type: SET_TITLE, payload: title, separator });
}

export function setTitleOverride(titleOverride) {
  return (dispatch) => dispatch({ type: SET_TITLE_OVERRIDE, payload: titleOverride });
}

export function setMetaInfo(metaObj) {
  return (dispatch) => dispatch({ type: SET_META_INFO, payload: metaObj });
}

export function setMetaDescription(content = '') {
  const metaDescription = `Get free shipping on ${content} styles at Neiman Marcus. Shop the latest luxury fashions from top designers.`;
  return (dispatch) => dispatch({ type: SET_META_DESCRIPTION, payload: { metaDescription } });
}

export function setMetaDescriptionCategory(categoryName = '') {
  const metaDescription = `Shop ${categoryName} at Neiman Marcus, where you will find free shipping on the latest in fashion from top designers.`;
  return (dispatch) => dispatch({ type: SET_META_DESCRIPTION, payload: { metaDescription } });
}

export function setFullMetaDescription(content = '') {
  const metaDescription = `${content}`;
  return (dispatch) => dispatch({ type: SET_META_DESCRIPTION, payload: { metaDescription } });
}

export function setMetaTags(content = []) {
  return (dispatch) => dispatch({ type: SET_META_TAG, payload: content });
}

export function setMetaInfoWithHTMLMetaTag(content = '') {
  return (dispatch) => dispatch({
    type: SET_META_INFO_PLP,
    payload: {
      ctpMetaInfo: content,
    },
  });
}

export function setPageId(pageId) {
  return (dispatch) => dispatch({ type: SET_PAGE_ID, payload: pageId });
}

export function setDecodedUEMtoUtag(email) {
  return (dispatch) => dispatch({ type: APP_SET_UEM_PARAM, email });
}

export function setPageVisited(payload) {
  return (dispatch) => dispatch({ type: PAGE_VISITED, page_data: payload });
}

export function setBreadcrumbs(payload) {
  return (dispatch) => dispatch({ type: RESOLVED_BREADCRUMB_CONTENT, payload });
}

export function setEditorialBreadcrumbs(payload) {
  return (dispatch) => dispatch({ type: RESOLVED_EDITORIAL_BREADCRUMB_CONTENT, payload });
}

export function setEditorialClass(payload) {
  return (dispatch) => dispatch({ type: RESOLVED_EDITORIAL_CLASS, payload });
}

export function domLoadCompleted() {
  return (dispatch) => dispatch({ type: DOM_LOAD_COMPLETE });
}

export function openSearchBoxModal(className, props) {
  return (dispatch) => dispatch(
    openModal({
      type: OPEN_SEARCH_BOX_MODAL,
      className,
      props,
    })
  );
}

export function openCollapseSearchBoxModal(className, props) {
  return (dispatch) => dispatch(
    openModal({
      type: OPEN_COLLAPSE_SEARCH_BOX_MODAL,
      className,
      props,
    })
  );
}

export function referralSourceClicked(source, linkType, customLinkData) {
  if (typeof window !== 'undefined') {
    const nmObj = window?.nm;
    if (nmObj?.om?.appendCookie && nmObj?.omPreviousPageCookieName) {
      const prevLink = JSON.stringify(
        customLinkData || { prev_page_link: source, prev_link_type: linkType }
      );
      nmObj.om.appendCookie(nmObj.omPreviousPageCookieName, prevLink, 0, '/');
    } else {
      logger.error('Could not set prev_page_link');
    }
  }
}

export const returnAdobeTargetQuery = (state = {}) => {
  const returnObj = {};
  ADOBE_QUERY_PARAMS.forEach((param) => {
    if (state[param]) {
      returnObj[param] = state[param];
    }
  });
  const akamaiEdgescape = state.akamaiEdgescape || {};
  if (akamaiEdgescape.country_code) returnObj.country = akamaiEdgescape.country_code;
  if (akamaiEdgescape.city) returnObj.city = akamaiEdgescape.city;
  if (akamaiEdgescape.zip) returnObj.zip = akamaiEdgescape.zip;
  if (akamaiEdgescape.continent) returnObj.continent = akamaiEdgescape.continent;
  if (akamaiEdgescape.region_code) returnObj.state = akamaiEdgescape.region_code;
  return returnObj;
};

export const returnOptimizelyQuery = (state = {}) => {
  const {
    akamaiEdgescape = {},
    _optuid: optuid,
  } = state;
  const returnObj = {};
  const UserAgentParams = [
    'isMobile',
    'isTablet',
    'isMobilePhone',
    'isDesktop',
    'browser',
    'version',
    'referer'];
  UserAgentParams.forEach((param) => {
    if (state[param]) {
      returnObj[param] = state[param];
    }
  });
  if (akamaiEdgescape.country_code) returnObj.country = akamaiEdgescape.country_code;
  if (akamaiEdgescape.city) returnObj.city = akamaiEdgescape.city;
  if (akamaiEdgescape.zip) returnObj.zip = akamaiEdgescape.zip;
  if (akamaiEdgescape.continent) returnObj.continent = akamaiEdgescape.continent;
  if (akamaiEdgescape.region_code) returnObj.state = akamaiEdgescape.region_code;
  if (optuid) returnObj.optuid = optuid;
  return returnObj;
};

export const resolveOptOptions = (opttids = '', forceOpty = '') => {
  if (!forceOpty) return opttids;

  const optVariations = forceOpty.split(',');
  const variations = [];
  for (let x = 0; x < optVariations.length; x++) {
    const variation = optVariations[x];
    const variationArr = variation.split(':');
    if (variationArr.length === 2) {
      variations.push({ experiment: variationArr[0], variation: variationArr[1] });
    }
  }

  const optExperiments = opttids.split(',');
  for (let y = 0; y < optExperiments.length; y++) {
    const experiment = optExperiments[y];
    const forcedVariation = find(variations, { experiment });
    if (forcedVariation) optExperiments[y] = `${forcedVariation.experiment}:${forcedVariation.variation}`;
  }

  return optExperiments.join(',');
};

export function logABTestAssignments(getState, testNames = '') {
  const abTestResponses = get(getState(), 'abTests.responses', []);
  testNames.split(',').forEach((mboxId) => {
    const testDetail = find(abTestResponses, { mboxId });
    const id = get(testDetail, 'assignment.tntVal', '');
    const exp = get(testDetail, 'assignment.value.experienceId', '');
    logger.info('abtest batch', {
      platform: 'Target',
      testName: mboxId,
      testId: id,
      experience: exp,
    });
  });
}

export function logOptABTestAssignments(optTests = {}) {
  for (const key of Object.keys(optTests)) {
    logger.info('abtest batch', {
      platform: 'Optimizely',
      testName: key,
      testId: key,
      experience: optTests[key].variation,
    });
  }
}

export function logABTestExperiences(testArray = []) {
  testArray.forEach((test) => {
    if (test.abTestName) {
      logger.info('abtest batch', {
        platform: 'Internal',
        testName: test.abTestName,
        testId: test.abTestId,
        experience: `${test.abTestExperience}:${test.abTestCookieValue}`,
      });
    }
  });
}

/* eslint no-unused-vars: ["error", { "args": "none" }] */
export function getABTestAssignments(mboxIdsIn, contentAsJson = false) {
  return (dispatch) => {
    dispatch({ type: LOADING_ABTEST });
    return new Promise((resolve) => { return resolve(); });
  };
}

export function getOptABTestAssignments(opttids) {
  return (dispatch, getState) => {
    const state = getState();
    if (get(state, 'device.isBOT', '') === '1') {
      dispatch({ type: REJECTED_ABTESTOPT });
      return new Promise((resolve) => { return resolve(); });
    }
    dispatch({ type: LOADING_ABTESTOPT });
    const { api = {}, session = {} } = state;
    const { requestContext = {} } = api;
    const { device = {} } = state;
    const additionalHeadersForOptimizely = returnOptimizelyQuery({
      ...session,
      ...requestContext,
      ...device,
    });
    const optParam = resolveOptOptions(opttids, get(session, 'force-opty', ''));
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
      ...additionalHeadersForOptimizely,
      pageUrl: get(session, 'url', ''),
      userAgent: get(api, 'requestContext.USER_AGENT', ''),
      opttid: optParam,
    };
    const ABTESTOPT_API_TIMEOUT = get(state, 'apiTimeouts.ABTESTOPT_API_TIMEOUT');
    const requestApi = httpWithLogging(state, ABTESTOPT_API_TIMEOUT);
    return requestApi.get(NMConfig.API_ABTEST_OPTIMIZELY, { headers })
      .then((successResponse) => {
        return dispatch({ type: RESOLVED_ABTESTOPT, payload: successResponse.data });
      }).then((response) => {
        logOptABTestAssignments(response.payload);
        return response;
      }).catch((e) => {
        logger.error('Exception in optimizely abTest call:', e);
        return dispatch({ type: REJECTED_ABTESTOPT });
      });
  };
}

export function getABTests(testIds = []) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_ABTESTS });
    const state = getState();
    const { session } = state;
    const { api } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    if (get(api, 'requestContext.TRUE_CLIENT_IP', '')) {
      headers.TRUE_CLIENT_IP = get(api, 'requestContext.TRUE_CLIENT_IP', '');
    }
    const testIdsParam = testIds.map((testId) => `${testId.abTestName}:${testId.abTestId}`);
    headers.abTestIds = testIdsParam.join(',');
    const ABTEST_API_TIMEOUT = get(state, 'apiTimeouts.ABTEST_API_TIMEOUT');
    const requestApi = httpWithLogging(state, ABTEST_API_TIMEOUT);
    const requestUrl = NMConfig.API_ABTEST_EXPERIENCES;

    return requestApi.get(requestUrl, { headers })
      .then((successResponse) => {
        parseAbTestValue(testIds, successResponse.data);
        for (let i = 0; i < testIds.length; i++) {
          if (testIds[i].abTestCookieValue) {
            dispatch({ type: SET_TEST_FROM_RESPONSE, payload: testIds[i] });
          }
        }
        return dispatch({ type: RESOLVED_ABTESTS });
      }).then(() => {
        logABTestExperiences(testIds);
      }).catch((e) => {
        logger.error('Exception in internal abTest Call:', e);
        return dispatch({ type: REJECTED_ABTESTS });
      });
  };
}

export function getApplicableOptyTestIds(
  defaultConfig = [],
  pageConfig = [],
  toggles = [],
  isDomestic = false,
  isPla = true
) {
  const isProductListingNotEnabledToggles = ['PDP_LAYOUT', 'ATB_UPT', 'CAROUSEL_REDESIGN'];
  const opttIds = [];

  [...defaultConfig, ...pageConfig].filter((cfg) => cfg).forEach((item) => {
    if (item?.isDomestic) {
      if (isProductListingNotEnabledToggles.includes(toggles?.[item?.toggle])) {
        if (!isPla) {
          isDomestic && toggles?.[item?.toggle] && opttIds.push(item?.optId);
        }
      } else {
        isDomestic && toggles?.[item?.toggle] && opttIds.push(item?.optId);
      }
    } else if (isProductListingNotEnabledToggles.includes(toggles?.[item?.toggle])) {
      if (!isPla) {
        toggles?.[item?.toggle] && opttIds.push(item?.optId);
      }
    } else {
      toggles?.[item?.toggle] && opttIds.push(item?.optId);
    }
  });
  return opttIds;
}

export function getCmsGlobalBasedOnToogle(
  isGlobalUpdateOn = false,
  siteTickerToggleForGenderURL = '',
  selectedGender = 'W',
) {
  let cmsGlobalType;
  if (siteTickerToggleForGenderURL === 'a') {
    cmsGlobalType = 'global';
  } else if (siteTickerToggleForGenderURL === 'b' && selectedGender === 'W') {
    cmsGlobalType = 'globaltestwomen';
  } else if (siteTickerToggleForGenderURL === 'b' && selectedGender === 'M') {
    cmsGlobalType = 'globaltestmen';
  } else if (!isGlobalUpdateOn) {
    cmsGlobalType = 'global';
  } else if (isGlobalUpdateOn) {
    cmsGlobalType = 'globalnav';
  }
  return cmsGlobalType;
}

export function openAppInstallModal() {
  return (dispatch) => dispatch(
    openModal({
      type: 'AppInstallModal',
      className: 'app-install-modal',
      closeDisabled: true,
    })
  );
}
