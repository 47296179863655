/* eslint no-underscore-dangle: ["error", { "allow": ["__STATE__", "_insertCss"] }] */
/* eslint func-names: ["error", "never"] */
import { mark, measure } from 'client/utilities/utilities-performance';
import React from 'react';
import get from 'lodash/get';
import Amplify from '@aws-amplify/core';
import 'first-input-delay';
import { guestConfiguration, configureAmplifyEnv, loadAdiScript } from '@nmg/auth';
import { hydrate } from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { useRouterHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import { parse, stringify } from 'query-string';
import { clearIfLocalStorageIsFull } from 'client-utils/utilities-localstorage';
import { checkInitialHit } from './utilities/initialHitFlag';
import { configureAmplify } from './utilities/amplifyUtils';
import { isTypeAheadOn, isCollapsedSearchBarOn } from './srp/utils/srpUtils';
import Store from './Store';
import RouterRoutes from './routes';
import './globals';
import './styles.scss';
import '@nmg/ui-footer/build/main.css';

mark('client bundle startup');
measure('client bundle startup');

window.perfMetrics = window.perfMetrics || {};
window.perfMetrics.onFirstInputDelay((delay, evt) => {
  if (typeof window.dtrum !== 'undefined') {
    const actionStartTime = (
      performance.timing.navigationStart
      + Math.floor(evt.timeStamp)
    );
    const actionEndTime = (
      performance.timing.navigationStart
      + Math.floor(evt.timeStamp + delay * 1000)
    );
    const action = window.dtrum.enterAction(
      'first-input-delay',
      'PerformanceMetric',
      actionStartTime,
      evt.type,
    );
    window.dtrum.leaveAction(action, actionEndTime);
  }
});

const getPreloadedState = () => {
  let preloadedState = null;
  if (typeof window.__STATE__ !== 'undefined') {
    // Grab the state from a global variable injected into the server-generated HTML
    preloadedState = window.__STATE__;

    // Allow the passed state to be garbage-collected
    delete window.__STATE__;

    if (preloadedState.state) {
      return preloadedState.state;
    }
  }
  const stateElement = document.getElementById('state');
  if (stateElement) {
    try {
      preloadedState = JSON.parse(stateElement.textContent);
    } catch (error) {
      throw new Error('Unable to parse state JSON');
    }
  }
  return preloadedState;
};

const node = document.getElementById('application');

const state = getPreloadedState();

clearIfLocalStorageIsFull();

if (state) {
  const store = Store.createStore(state);
  // eslint-disable-next-line
  const env = state?.env_name?.env;
  // eslint-disable-next-line
  const brand = state?.brand_name?.env || 'NM';
  const {
    FINGERPRINT_PRO,
    GUEST_IDENTITY_DT,
    PZP_IDENTITY,
    DISABLE_ATG_LOGIN,
    ADOBE_PZP_IDENTITY,
    USE_AUTH_CONFIG,
    P13N_API,
    USE_CONFIDENTIAL_CLIENT,
    ADI_SCRIPT,
    FP_OPENSOURCE,
  } = state.toggles;

  USE_AUTH_CONFIG
    ? Amplify.configure(configureAmplifyEnv(state.toggles))
    : configureAmplify(env, brand, USE_CONFIDENTIAL_CLIENT);

  guestConfiguration(
    FINGERPRINT_PRO,
    GUEST_IDENTITY_DT,
    env, brand,
    PZP_IDENTITY,
    DISABLE_ATG_LOGIN,
    ADOBE_PZP_IDENTITY,
    P13N_API,
    FP_OPENSOURCE
  );
  const createAppHistory = useRouterHistory(createBrowserHistory);
  const appHistory = createAppHistory({
    parseQueryString: (queryString) => parse(queryString),
    stringifyQuery: (queryObj) => stringify(queryObj),
  });
  const history = syncHistoryWithStore(appHistory, store);
  ADI_SCRIPT ? loadAdiScript() : null;
  window && window.addEventListener('DOMContentLoaded', () => {
    ['responseEnd', 'domInteractive', 'domComplete'].reduce((prev, curr) => {
      measure(`${prev} » ${curr}`, prev, curr);
      measure(curr, 'navigationStart', curr);
      return curr;
    }, 'navigationStart');
    if (!document.getElementById('searchbox_bloomreach')) {
      const script = document.createElement('script');
      const typeAheadActive = isTypeAheadOn(state) || isCollapsedSearchBarOn(state);
      script.src = `/assets/scripts/searchResponsive_${typeAheadActive ? 'typeahead' : 'recentsearch'}.js?v=082024`;
      script.setAttribute('defer', true);
      script.setAttribute('id', 'searchbox_bloomreach');
      document.head.appendChild(script);
    }
  });

  checkInitialHit(DISABLE_ATG_LOGIN);
  // TODO: Refactor code using window.store, should not set store to global object for perf
  window.store = store;

  if (get(state, 'toggles.WHY_DID_YOU_UPDATE', false)) {
    const loadWhyDidYouUpdate = async () => {
      const { whyDidYouUpdate } = await import(
        /* webpackChunkName: "why-did-you-update" */
        'why-did-you-update'
      );
      whyDidYouUpdate(React);
    };
    loadWhyDidYouUpdate();
  }

  const routes = RouterRoutes(state.toggles.CRP, state.toggles.SRP_SCROLL_FIX);

  window.main = () => {
    Loadable.preloadReady()
      .then(() => {
        hydrate((
          <Provider store={store}>
            <Router history={history}>
              {routes}
            </Router>
          </Provider>
        ), node);
      });
  };

  if ('serviceWorker' in navigator) {
    if (get(state, 'toggles.ACN_SW_ENABLE', false)) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js'); // eslint-disable-line max-len, compat/compat
      });
    } else {
      navigator.serviceWorker.getRegistrations().then((registrations) => { // eslint-disable-line max-len, compat/compat
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
    if (get(state, 'toggles.PDP_SW', false)) {
      navigator.serviceWorker.getRegistrations().then((registrations) => { // eslint-disable-line max-len, compat/compat
        for (const registration of registrations) {
          const { scope = '' } = registration;
          if (scope.includes('/p/')) {
            registration.unregister();
          }
        }
      });
    }
  }
} else {
  window.main = () => null;
}
