import React from 'react';
import Loadable from 'react-loadable';
import { Route, IndexRoute } from 'react-router';
import { getParams } from 'client-utils/utilities-router';
import App from './common/components/App/App';

const AppHomeWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-home' */ './common/components/App/app-home-wrapper'),
  loading: () => false,
});

const AppProductWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-product' */ './common/components/App/app-product-wrapper'),
  loading: () => false,
});

const AppPromotionsWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-promotions' */ './common/components/App/app-promotions-wrapper'),
  loading: () => false,
});

const AppEditorialWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-editorial' */ './common/components/App/app-editorial-wrapper'),
  loading: () => false,
});

const AppQuizWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-quiz' */ './common/components/App/app-quiz-wrapper'),
  loading: () => false,
});

const AppLogin = Loadable({
  loader: () => import(/* webpackChunkName: 'app-login' */ './profile/components/AccountAccess/AccountAccessWrapper'),
  loading: () => false,
});
const AppRegisterWithUs = Loadable({
  loader: () => import(/* webpackChunkName: 'app-registerWithUs' */ './profile/components/RegisterWithUs/RegisterWithUs'),
  loading: () => false,
});
const AppOrderHistory = Loadable({
  loader: () => import(/* webpackChunkName: 'app-orderHistory' */ './common/components/App/app-order-history-wrapper'),
  loading: () => false,
});
const AppCcpaForm = Loadable({
  loader: () => import(/* webpackChunkName: 'app-ccpaForm' */ './common/components/App/app-ccpa-form-wrapper'),
  loading: () => false,
});
const AppAccountOverview = Loadable({
  loader: () => import(/* webpackChunkName: 'app-account-overview' */ './common/components/App/app-account-overview-wrapper'),
  loading: () => false,
});
const AppResetPassword = Loadable({
  loader: () => import(/* webpackChunkName: 'app-resetPassword' */ './profile/components/ResetPassword/ResetPassword'),
  loading: () => false,
});
const AppGuestOrderHistory = Loadable({
  loader: () => import(/* webpackChunkName: 'app-guestOrderHistory' */ './common/components/App/app-guest-order-history-wrapper'),
  loading: () => false,
});
const NMCreditCardWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-nm-creditcard' */ './common/components/App/app-nm-creditcard-wrapper'),
  loading: () => false,
});

const AppFavorites = Loadable({
  loader: () => import(/* webpackChunkName: 'app-favorites' */ './common/components/App/app-favorites-wrapper'),
  loading: () => false,
});

const checkoutWrapper = Loadable({
  loader: () => import('./common/components/App/app-checkout-wrapper'),
  loading: () => false,
});

const cartWrapper = Loadable({
  loader: () => import('./common/components/App/app-cart-wrapper'),
  loading: () => false,
});

const orderConfirmationInternational = Loadable({
  loader: () => import('./common/components/App/app-orderConfirmation'),
  loading: () => false,
});

const ThematicPageWrapper = Loadable({
  loader: () => import('./common/components/App/app-th-wrapper'),
  loading: () => false,
});
const FTCHold = Loadable({
  loader: () => import(/* webpackChunkName: 'app-ftc' */ './common/components/App/app-ftchold-wrapper'),
  loading: () => false,
});


export function scrollToTop() {
  const scrollToTop = getParams(window.location).scroll || 'true';
  const isScrollFixToggleOn = window.sessionStorage.getItem('isScrollFixToggleOn');
  const isComingBackFromPDP = window.sessionStorage.getItem('visitedPDPpage');
  const isInitialPLPload = window.sessionStorage.getItem('initialPLPload');

  if (scrollToTop === 'true') {
    if (isScrollFixToggleOn === 'true' && isComingBackFromPDP === 'true') {
      if (isInitialPLPload === 'true') {
        window.sessionStorage.removeItem('visitedPDPpage');
        window.sessionStorage.removeItem('initialPLPload');
        window.scrollTo(0, 0);
      }
      window.sessionStorage.removeItem('visitedPDPpage');
    } else {
      window.scrollTo(0, 0);
      if (isScrollFixToggleOn === 'true') {
        window.sessionStorage.removeItem('initialPLPload');
      }
    }
  }
}

export function scrollToTopSrp() {
  const scrollToTop = getParams(window.location).scroll || 'true';
  const isScrollFixToggleOn = window.sessionStorage.getItem('isScrollFixToggleOn');
  const isComingBackFromPDP = window.sessionStorage.getItem('visitedPDPpage');
  const isInitialPLPload = window.sessionStorage.getItem('initialPLPload');
  const isPagePLP = window.location.pathname.indexOf('/p/') !== -1;

  if (scrollToTop === 'true') {
    if (isScrollFixToggleOn === 'true' && isComingBackFromPDP === 'true') {
      if (isInitialPLPload === 'true') {
        window.sessionStorage.removeItem('visitedPDPpage');
        window.sessionStorage.removeItem('initialPLPload');
        window.scrollTo(0, 0);
      }
      if (!isPagePLP) {
        window.sessionStorage.removeItem('visitedPDPpage');
      }
    } else {
      window.scrollTo(0, 0);
      if (isScrollFixToggleOn === 'true') {
        window.sessionStorage.removeItem('initialPLPload');
      }
    }
  }
}

const conditionalScrollToTop = (isSrpScrollFix) => (isSrpScrollFix ? scrollToTopSrp : scrollToTop);

const RouterRoutes = (isCRPOn, isSrpScrollFix) => {
  return (
    <Route path="/(en-:countryCode)" component={App} onChange={conditionalScrollToTop(isSrpScrollFix)}>
      <IndexRoute component={AppHomeWrapper} deferHpScripts />
      <Route path="index.jsp" component={AppHomeWrapper} deferHpScripts />
      <Route path="mens" component={AppHomeWrapper} deferHpScripts />
      <Route path="(*/):productId/p.prod" component={AppProductWrapper} />
      <Route path="p/:productInfo" component={AppProductWrapper} />
      <Route path="specialoffers" component={AppPromotionsWrapper} />
      <Route path="login(/:optParam)" component={AppLogin} />
      <Route path="register" component={AppRegisterWithUs} />
      <Route path="order-history(/:optParam1)" component={AppOrderHistory} />
      <Route path="accountoverview" component={AppAccountOverview} />
      <Route path="/CCPA" component={AppCcpaForm} />
      <Route path="resetpassword" component={AppResetPassword} />
      <Route path="services/toTheTrade" component={AppEditorialWrapper} />
      <Route path="services/aboutHorchow" component={AppEditorialWrapper} />
      <Route path="services/NMCreditCard" component={NMCreditCardWrapper} deferScripts />
      <Route path="services/GiftCards" component={AppEditorialWrapper} />
      <Route path="editorial/rug-guide" component={AppEditorialWrapper} />
      <Route path="service/sitemap" component={AppEditorialWrapper} />
      <Route path="guest-order-history(/:optParam)" component={AppGuestOrderHistory} />
      <Route path="editorial(/:optParam1)(/:optParam2)(/:optParam3)" component={AppEditorialWrapper} />
      <Route path="quiz(/:optParam1)(/:optParam2)(/:optParam3)" component={AppQuizWrapper} />
      <Route path="designer(/:optParam1)(/:optParam2)(/:optParam3)" component={AppEditorialWrapper} />
      <Route path="services(/:optParam1)(/:optParam2)(/:optParam3)" component={AppEditorialWrapper} />
      <Route path="seasonal(/:optParam1)(/:optParam2)(/:optParam3)" component={AppEditorialWrapper} />
      <Route path="favoriteitems" component={AppFavorites} />
      <Route path="international/checkout" component={checkoutWrapper} />
      <Route path="international/cart" component={cartWrapper} />
      <Route path="international/order-confirmation" component={orderConfirmationInternational} />
      <Route path="services/SpecialOffer" component={AppEditorialWrapper} />
      <Route path="(*/):thPageParam-th.html" component={ThematicPageWrapper} />
      <Route path="customer-preferences/ftc(/:optParam)" component={FTCHold} />
    </Route>
  );
};

export default RouterRoutes;
