import {
  CLOSE_PRODUCT_PANEL,
  OPEN_PRODUCT_PANEL,
  LOADING_PRODUCT_PANEL,
  ERROR_PRODUCT_PANEL,
} from 'cms/actions';

const initialState = {
  open: false,
  styledForYouCard: null,
  products: [],
  productsYMAL: [],
  numberOfProducts: 0,
  numberOfProductsYMAL: 0,
  loading: false,
  error: '',
  ppOpener: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADING_PRODUCT_PANEL:
      return {
        ...state,
        open: true,
        loading: true,
        numberOfProducts: action.payload.products,
        numberOfProductsYMAL: action.payload.productsYMAL,
      };
    case OPEN_PRODUCT_PANEL:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        productsYMAL: action.payload.productsYMAL,
        styledForYouCard: action.payload.styledForYouCard,
        ppOpener: action.payload.ppOpener,
      };
    case ERROR_PRODUCT_PANEL:
      return {
        ...state,
        loading: false,
        error: action.payload.msg,
        products: [],
        productsYMAL: [],
        numberOfProducts: action.payload.numberOfProducts,
        numberOfProductsYMAL: action.payload.numberOfProductsYMAL,
      };
    case CLOSE_PRODUCT_PANEL:
      return {
        ...state,
        open: false,
        loading: false,
        error: '',
        products: [],
        productsYMAL: [],
        numberOfProducts: 0,
        numberOfProductsYMAL: 0,
        styledForYou: false,
      };
    default:
      return state;
  }
};
